import academy1 from "../../assets/academy/academy1.png"; 
import academy2 from "../../assets/academy/academy2.png"; 
import academy3 from "../../assets/academy/academy3.png"; 
import academy4 from "../../assets/academy/academy4.png"; 
import academy5 from "../../assets/academy/academy5.png"; 
import Container from "../Main/BlockElement/Container/Container";
import Section from "../Main/BlockElement/Section/Section";
import Image from "../Main/MediaElement/Image/Image";
import Heading from "../Main/TextElement/Heading/Heading";
import Paragraph from "../Main/TextElement/Paragraph/Paragraph";

export default function SweetAcademy() {

  const upperRow = [academy1, academy2, academy3].map((imageSrc, index) => (
    <Container className="flex-1" key={index}>
      <Image src={imageSrc} className="w-full" />
    </Container>
  ));

  const lowerRow = (
    <>
      <Container className="flex-1">
        <Heading type="h1" className="font-playfair text-4xl font-medium mb-5" items={[]} htmlFor="">SWEET<br />AKADEMIA</Heading>
        <Paragraph className="font-playfair md:text-lg text-sm" items={[]} htmlFor="">
          Sweet Academy to miejsce, w którym <br /> 
          zdobędziesz wiedzę, jak prawidłowo korzystać <br />
          z produktów i wdrażać je w swoim salonie. <br />
          Oferujemy zarówno szkolenia stacjonarne, <br /> 
          jak i internetowe, podczas których nasi <br />
          edukatorzy zapewnią Ci wszystko, czego <br />
          potrzebujesz, aby cieszyć się fenomenalnymi <br />
          efektami naszych nowoczesnych kosmetyków.
        </Paragraph>
      </Container>

      {[academy4, academy5].map((imageSrc, index) => (
        <Container className="flex-1" key={index}>
          <Image src={imageSrc} alt="Academy" className="w-full" />
        </Container>
      ))}
    </>
  );

  return (
      <Section className="mx-auto w-4/5">
        <Container className="flex flex-col md:flex-row gap-10 mb-10">{upperRow}</Container>
        <Container className="flex flex-col md:flex-row gap-10 mb-10">{lowerRow} </Container>
      </Section>
  );
}
