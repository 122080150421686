import React, { useState, useEffect } from "react";
import Nav from "../../BlockElement/Nav/Nav";
import Container from "../../BlockElement/Container/Container";
import { Link, useLocation } from "react-router-dom";
import Image from "../../MediaElement/Image/Image";
import navigationItems from "../../../../utils/navigationItems";
import logo from "../../../../assets/logo.svg";
import arrow from "../../../../assets/arrow.png";

export default function NavbarDesktop() {
  const [isAfter, setIsAfter] = useState(false);
  const location = useLocation();

  const [isHome, setIsHome] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsAfter(window.scrollY > 200);
    };

    const home = location.pathname === "/";

    setIsHome(home);

    if (home) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  return (
    <Nav
      className={`p-5 w-full z-10 transition-colors ${
        isHome && !isAfter
          ? "bg-transparent hover:bg-sp-light-pink duration-300 ease-out"
          : "bg-sp-light-pink duration-300 ease-out"
      }
`}
    >
      <Container className="flex items-center gap-[50px] text-xl">
        <Link to="/">
          <Image src={logo} className="h-[60px]" />
        </Link>

        <Container className="flex-1 flex justify-end items-center gap-10 font-lato">
          {navigationItems.map((item) => (
            <Container key={item.title} className="relative group">
              <Container className="text-sp-gray flex gap-2 items-center">
                <Link
                  to={item.href ?? ""}
                  className={item.href ? "hover:text-sp-sweet-pink" : ""}
                >
                  {item.title}
                </Link>
                {item.children && (
                  <Container className="text-[12px] duration-300 group-hover:rotate-180">
                    <Image src={arrow} />
                  </Container>
                )}
              </Container>
              {item.children && (
                <Container className="hidden z-50 absolute group-hover:flex flex-col bg-sp-light-pink rounded-b-md p-[15px] min-w-max">
                  {item.children?.map((child) => (
                    <Link
                      className="hover:text-sp-sweet-pink bg-sp-light-pink text-sp-black p-[10px]"
                      key={child.title}
                      to={child.href ?? ""}
                    >
                      {child.title}
                    </Link>
                  ))}
                </Container>
              )}
            </Container>
          ))}
          {/* <Link
            to="/auth/signin"
            className="rounded rounded-3xl border border-sp-dark p-[8px] px-[35px] hover:shadow-lg hover:bg-sp-sweet-pink hover:text-white hover:border-sp-sweet-pink transform transition duration-900 ease-in-out"
          >
            Zaloguj się
          </Link> */}
        </Container>
      </Container>
    </Nav>
  );
}
