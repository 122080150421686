import Container from "../../Main/BlockElement/Container/Container";
import Heading from "../../Main/TextElement/Heading/Heading";
import ProductIngredient from "../ProductIngredient/ProductIngredient";
import { IProductsFormula } from "../../../types/IProductFormula";
import Section from "../../Main/BlockElement/Section/Section";

export default function ProductsFormula ({ ingredients }: IProductsFormula) {
  return (
      <Section className={"my-10 md:px-10"}>
        <Heading items={[]} htmlFor="" type="h2" className="font-medium text-xl md:text-4xl font-playfair w-full text-center py-5">SKŁAD PRODUKTÓW</Heading>
        <Container className={"flex flex-col md:flex-row"}>
          <ProductIngredient {...ingredients[0]} />
          <ProductIngredient {...ingredients[1]} />
          <ProductIngredient {...ingredients[2]} />
          <ProductIngredient {...ingredients[3]} />
        </Container>
      </Section>
  );
}
