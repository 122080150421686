import Image from "../../Main/MediaElement/Image/Image";
import Container from "../../Main/BlockElement/Container/Container";
import Paragraph from "../../Main/TextElement/Paragraph/Paragraph";
import ProductFeatures from "../ProductFeatures/ProductFeatures";
import Heading from "../../Main/TextElement/Heading/Heading";
import Section from "../../Main/BlockElement/Section/Section";
import { IProductHeader } from "../../../types/IProductHeader";

export default function ProductHeader({ image,name,nameDescription,description,primaryColor,secondaryColor,circle }: IProductHeader) {

  const text = (
    <Container className="flex w-full flex-col items-stretch justify-center pb-5">
      <Heading type="h1" items={[]} htmlFor={""} className={`px-8 text-left text-2xl md:text-6xl py-4 font-medium font-playfair text-${primaryColor}`}>{name}</Heading>
      <Heading type="h2" items={[]} htmlFor={""} className={`px-8 text-left text-xl md:text-2xl py-2 font-medium font-playfair text-${primaryColor}`}>{nameDescription}</Heading>
      <Paragraph items={[]} htmlFor={""} className={`px-8 text-left text-sm md:text-lg font-light font-playfair text-${primaryColor}`}>{description}</Paragraph>
    </Container>
  );

  return (
    <Section className="sm:pt-[100px]">
      <Container className="flex flex-col sm:flex-row w-full items-start sm:items-center justify-start bg-sp-light-gray">
        <Image src={image} alt={name} className="w-full m-0 p-0" />
        <Container className={`relative sm:absolute ml-0 sm:ml-8 flex w-full sm:w-1/3 items-center justify-center bg-${secondaryColor}`}>{text}</Container>
      </Container>
      <ProductFeatures circle={circle} secondaryColor={secondaryColor} primaryColor={primaryColor} />
    </Section>
  );
}
