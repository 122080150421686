import Container from "../Main/BlockElement/Container/Container";
import Section from "../Main/BlockElement/Section/Section";
import Image from "../Main/MediaElement/Image/Image";
import aboutus from '../../assets/aboutus/aboutus.webp';
import Heading from "../Main/TextElement/Heading/Heading";
import Paragraph from "../Main/TextElement/Paragraph/Paragraph";

export default function Us() {
    return(
            <Section className="w-full bg-sp-light-gray flex justify-center items-end">
                <Container>
                    <Image src={aboutus} alt="AboutUs" className="w-full object-cover brightness-50" />
                    <Image src={aboutus} alt="AboutUs" className="w-full object-cover brightness-50 md:hidden" />
                </Container>

                <Container className="absolute flex w-4/5 md:w-2/3 h-3/5 md:h-2/5 items-center justify-center bg-sp-light-pink">
                    <Container className="flex flex-col items-stretch justify-center w-full">
                        <Heading type="h1" items={[]} htmlFor={""} className="font-playfair text-md md:text-4xl text-center font-medium pb-2">O nas</Heading>
                        <Paragraph items={[]} htmlFor={""} className="font-playfair text-sm md:text-xl text-center md:mt-5 font-light">
                            Sweet Professional to marka, wyróżniająca się na rynku, dzięki nowoczesnej <br />
                            technologii i starannie dobranym składnikom. <br />
                            Wasze włosy - nasze zaangażowanie.
                        </Paragraph>
                    </Container>
                </Container>
            </Section>
    );
}